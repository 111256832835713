<template>
    <div class="login">
        <van-nav-bar
            title="登录"
            fixed
            left-arrow
            @click-left="$router.push({name:'home'})"
        />
        <div class="panel">
            <div class="title">短信登录</div>
            <div class="ipt-wrapper username">
                <input class="ipt" type="number" name="username" ref="username" v-model="username" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="手机号码" />
            </div>
            <div class="ipt-wrapper sms">
                <input class="ipt" type="number" oninput="if(value.length>4)value=value.slice(0,4)" name="sms" ref="sms" v-model="sms" placeholder="验证码" />
                <span v-if="leftTime<=0" class="color-blue" @click="getCode">获取验证码</span>
                <div v-else class="color-gray">
                    <span>{{ leftTime }}秒后可重新发送</span>
                </div>
            </div>
            <div v-if="i" class="ipt-wrapper color-gray">
                <span>邀请码</span> <span>{{ i }}</span>
            </div>
            <van-button block type="info" class="login-btn" :class="disBtn?'dis-btn':''" @click="login">立即登录</van-button>
            <div class="color-gray2 agree">
                <van-checkbox class="agree-checkbox" v-model="agree" />
                <div>勾选同意<span class="color-blue" @click="$router.push({name:'agree',params:{t:'user'}})">《用户协议》</span>和<span class="color-blue" @click="$router.push({name:'agree',params:{t:'private'}})">《隐私协议》</span></div>
            </div>
        </div>
           
    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    name: 'Login',
    data(){
        return {
            loading: false,
            agree: false,
            leftTime: 0,
            i: '',
            sms: '',
            username: '',
            itvl: {}
        }
    },
    computed: {
        disBtn(){
            return !(this.agree && this.sms && this.username);
        }
    },
    components:{
    },
    mounted(){
        if(this.$route.params.i){
            this.i = this.$route.params.i;
        }
        this.wecheck();
        // this.$refs.username.focus();
        // let username = localStorage.getItem("gmcUserId");
        // if (username) {
        //     this.email = username;
        //     this.password = Base64.decode(localStorage.getItem("gmcPassword"));// base64解密
        //     this.rememberMe = true;
        // }
    },
    methods:{
        wecheck(){
            if(window.IS_WECHAT){
                this.$router.push({name: 'weauth'});
            }else{
                Toast("请在微信浏览器中打开");
            }
        },
        login(){
            if(!/^1\d{10}$/.test(this.username)){
                Toast('请输入正确的手机号码');
                return;
            }
            if(!/^\d{4}$/.test(this.sms)){
                Toast('请输入正确的验证码');
                return;
            }
            if(!this.agree) {
                Toast('请阅读用户和隐私协议');
                return;
            }
            
            this.loading = true;
            let _this = this;
            
            let data = {
                phone: this.username,
                sms: this.sms
            };
            this.$http.call(this.$http.api.login,{data:data}).then(function(res){
                let resp = res.data;
                console.log(resp)
                if(resp.code=='0'){
                    _this.$store.commit('setToken', resp.token.access_token);
                    _this.$store.commit('setUserinfo', resp.user);
                    _this.$router.push({name:'home'});
                }else{
                    Toast(resp.msg);
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                _this.loading = false
            });
        },
        goUserAgree(){
            this.$router.push({name:'agree',params:{t:'user'}});
        },
        getCode(){
            console.log('getOtp');
            if(this.loading) return;
            if(!/^1\d{10}$/.test(this.username)){
                Toast('请输入正确的手机号码');
                return;
            }
            let data = {
                phone: this.username
            };
            let _this = this;
            this.loading = true;
            this.$http.call(this.$http.api.sms,{
                params: {scene: 'login'},
                data
            }).then((res)=>{
                let resp = res.data;
                console.log(resp)
                Toast(resp.msg)
                _this.leftTime = 60;
                _this.itvl = setInterval(()=>{
                    _this.leftTime -= 1;
                    if(_this.leftTime <= 0){
                        clearInterval(_this.itvl);
                    }
                },1000);
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                this.loading = false;
            });
        }, 
        findPass(){
            console.log('findpass');
            let _this = this;
            let _reg = /^([a-zA-Z\d][\w-.]{2,})@(\w{2,}).([a-z]{2,})(.[a-z]{2,})?$/;
            if(!this.email || !_reg.test(this.email)){
                this.$message.error(_this.$t('register.tips.emailError'));
                return;
            }
            let data = {
                email: this.email, password: this.newPassword, otp: this.otpCode
            };
            // console.log(data)
            this.$http.call(this.$http.api.findPass,{
                data
            }).then((res)=>{
                console.log(res);
                let resp = res.data;
                if(resp.code == '200'){
                    _this.$message.success(_this.$t('register.sendSuccess'));
                    _this.scene = 'login';
                    // _this.$router.push({name: 'login'});
                }else{
                    let _k = _this.$t('login.tips.'+resp.data);
                    _this.$message.error(_k);
                }
                
            },(res)=>{
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                this.loading = false;
            });
        }
    }
}
</script>
<style lang="less">
.login{
    width: 100%;
    color: #333;
    padding-top: 100px;
    font-size: 14px;
    .panel {
        margin: 50px 30px;
        .title {
            text-align: left;
            font-weight: bold;
            padding-bottom: 15px;
        }
        .ipt-wrapper {
            margin-bottom: 35px;
            background: #fefefe;
            border: 1px solid #f1f1f1;
            border-radius: 4px;
            padding: 10px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ipt {
                flex: 1;
                outline: none;
                border: none;
                background: transparent;
            }
        }
        .login-btn {
            border-radius: 4px;
        }
        .dis-btn {
            opacity: .7;
        }
        .agree {
            padding: 15px 0;
            font-size: 13px;
            display: flex;
            height: 20px; 
            line-height: 20px;
            .agree-checkbox {
                padding-right: 4px;
            }
        }
    } 
}
</style>
